@import "../../styles/helpers";

.ladingHeader {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  flex-wrap: nowrap !important;

  @media (max-width: breakpoint-max(xs)) {
    overflow: visible;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}

.ladingHeaderContent {
  position: sticky;
  width: 100%;
  z-index: 1;
  height: $navbar-height;
  max-width: 1200px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 30px;
  height: auto;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}

.registerButton {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px !important;
  gap: 8px !important;
  height: 32px !important;

  background: #f4f4f4 !important;
  border-radius: 16px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 32px !important;
  color: black !important;
  outline: none !important;
  border: none !important;
  max-width: 200px !important;
}

.loginButton {
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 32px !important;
  color: black !important;
}

.pageContainer {
  width: 100%;
  height: calc(100vh - 90px);
  padding-bottom: 90px;
  display: flex;
  justify-content: center;

  @media (max-width: breakpoint-max(md)) {
    height: auto;
  }
}

.contentContainer {
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: breakpoint-max(md)) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
}

.leftColumn {
  width: 45%;
  display: flex;
  gap: 25px;
  flex-direction: column;
  margin-bottom: 70px;

  @media (max-width: breakpoint-max(md)) {
    width: 100%;
    margin-bottom: 40px;
  }
}

.title {
  font-weight: 800;
  //   font-size: 55px;
  line-height: 80px !important;
  color: black;

  font-size: 75px;
  line-height: 100.8px;

  @media (max-width: breakpoint-max(xs)) {
    font-size: 45px;
    line-height: 60px;
  }
}

.description {
  font-weight: normal;
  //   font-size: 16px;
  //   line-height: 22px;
  font-size: 18px;
  line-height: 27px;
}

.tryButton {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px !important;
  gap: 8px;

  background: #296df1 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  line-height: 24px !important;
  color: white !important;
  outline: none !important;
  border: none !important;
}

.rightColumn {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: breakpoint-max(md)) {
    width: 70%;
  }
}

.arrow {
  width: 10px;
  height: auto;
}

.demoImage {
  object-fit: contain;
  width: 60%;

  @media (max-width: breakpoint-max(md)) {
    width: 100%;
  }
}

.tryArrow {
  position: absolute;
  left: 150px;
  top: 14px;
  width: 39px;
  height: auto;

  @media (max-width: breakpoint-max(xs)) {
    display: none;
  }
}

.tryDescription {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  position: absolute;
  top: 140px;

  @media (max-width: breakpoint-max(xs)) {
    display: none;
  }
}

.gap10 {
  gap: 10px;
}

.languages {
  position: fixed;
  bottom: 36px;
  right: 20px;
}
