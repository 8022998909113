@import '../../styles/helpers';

.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.errorContainer {
  margin: 80px auto;
  width: 426px;
  text-align: center;
}

.errorCode {
  font-size: 72px;
  font-weight: $font-weight-bold;
  line-height: 1;
  color: $violet;
}

.errorInfo {
  margin: 16px auto 32px;
  font-size: 16px;
  
  color: black;
}

.errorHelp {
  margin-bottom: 32px;
  font-size: 1rem;
  
  color: black;
}

.errorBtn {
  font-size: 16px !important;
  // font-weight: normal !important;
  // line-height: 32px !important;
  // color:  !important;
}

.imageContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 84px 0 60px;
  background-color: $white;

  @media (max-width: breakpoint-max(md)) {
    padding: 40px 0;
  }
}

.errorImage {
  width: 674px;
  height: 400px;

  @media (max-width: breakpoint-max(md)) {
    width: 296px;
    height: 176px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: $layout-padding;
  background-color: $white;
  color: $base-dark-gray;
  font-size: $font-size-sm;
}

.footerLabel {
  margin-right: 4px;
}

.pageContainer {
  width: 100%;
  min-height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  max-width: 850px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}