.conainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 8px;
    margin-right: 18px;

    color: black;

    user-select: none;
}

.icon {
    width: 34px;
    height: auto;
}