.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */ /* Blue */
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1.2s linear infinite;
  animation-timing-function: ease-in-out;
}

@keyframes spin {
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
}