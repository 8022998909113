.auth-page {
  height: 100vh;

  .left-column {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $base-light-gray;
  }

  .right-column {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: $white;

    @media (max-width: breakpoint-max(md)) {
      display: none;
    }
  }
}

.widget-auth {
  min-width: 340px;
  // box-shadow: 4px 4px 16px 0 rgba(77, 83, 224, 0.2);
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 30px;

  @media (max-width: breakpoint-max(xs)) {
    width: 100vw;
  }

  .auth-header {
    font-size: 25px;
    font-weight: 700;
    color: black;
  }

  .logo-block {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin-left: 12px;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }
  }

  a {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
  }
}

.auth-info {
  // padding: 18px 21px;
  border-radius: .5rem;
  background: $body-bg;
  color: $base-dark-gray;
}

.form-control {
  color: $base-dark-gray;
}

.dividing-line {
  display: flex;
  flex-direction: row;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
}

.dividing-line:before,
.dividing-line:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid $base-middle-gray;
  margin: auto;
}

.social-label {
  margin-right: 1rem;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
}

.socials {
  a {
    margin: 0 .5rem;
  }
}

.form-title {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 32px !important;
  text-transform: capitalize;
  height: 32px;
  color: black !important;
  margin: 0px !important;
}

.form-link {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 32px !important;
  text-transform: capitalize;
  height: 32px;
  color: #296DF1;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.DayPicker-Week {
  line-height: 1.2;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}