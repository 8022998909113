@import "../../styles/helpers";
@import "../../../node_modules/pretty-checkbox/src/pretty-checkbox.scss";

.header {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  flex-wrap: nowrap !important;

  @media (max-width: breakpoint-max(xs)) {
    overflow: visible;
  }
}

.logo {
  width: 35px;
  height: auto;
  margin-top: 2px;
}

.root {
  position: sticky;
  width: 100%;
  z-index: 1;
  height: $navbar-height;
  max-width: 780px;
  background-color: white;

  :global {
    position: sticky;

    .input-group {
      overflow: hidden;
    }

    .input-group-prepend {
      background-color: $white;
      transition: background-color ease-in-out 0.15s;
      padding-right: 1rem;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .form-control {
      background-color: $white;
      height: 45px;
      border: none;
      border-radius: 0.5rem;
      padding-left: 1rem;
      font-size: $font-size-base;
    }

    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;

      .navbar-dropdown-toggle {
        color: black;

        span {
          @media screen and (max-width: breakpoint-max(sm)) {
            max-width: 40px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.navbarBlock {
  display: flex;
  color: $icon-color;

  i::before {
    color: black;
  }
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 44px;
  width: 44px;
  border-radius: 50%;

  img {
    height: 100%;
  }
}

.menuIcon {
  fill: $icon-color;
}

.notificationImage {
  margin: 8px auto 12px;
  width: 100%;
}

.mariaImage {
  margin-right: 12px;
  width: 40px;
  height: 40px;
}

.count {
  position: absolute;
  width: 7px;
  height: 7px;
  top: 10px;
  right: 3px;
  background: $pink;
  border-radius: 50%;
  position: absolute;
  right: 3px;
  top: 2px;
}

.form-control::placeholder {
  color: red;
  opacity: 1;
}

.dropdownProfileItem {
  display: flex;
  &:hover {
    svg {
      path {
        fill: $default;
        stroke: $default;
      }
    }
  }
}

.dropdownProfileContainerWithSettings {
  display: flex;
  align-items: center;
}

.dropdownProfileItemWithSettings {
  width: calc(100% - 50px) !important;
}

.createProjectDropdownItemParent {
  background-color: $base-light-gray !important;
}

.createProjectDropdownItem {
  color: black;
}

.secondaryTextElement {
  color: $text-secondary;
}

.pointer {
  cursor: pointer;
}

.settingsIcon {
  color: $text-secondary !important;
  right: 2px;
  margin-left: 13px;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

// input[type="checkbox"] {
//   content: url("../../components/Icons/reports-off.svg");
//   display:inline-block;
//   border: none;
//   width: 23px;
//   height: 14px;
//   margin-right: 10px;
//   // background: red;
//   background: url("../../components/Icons/reports-off.svg");

//   &:checked {
//     content: "";
//   }
// }

// input[type="checkbox"]:checked::before {
// .state:checked::before {
//   background-color: red !important;
// }

// .switchOff::before {
// content: url("../../components/Icons/reports-off.svg");
// width: 23px;
// height: 14px;
// }

.searchIconLink {
  line-height: normal !important;
  margin-top: 4px;
}

.searchIconLink i::before {
  color: black !important;
}

.profileMenu {

}

.profileMenuItem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 13px 16px;
  border-radius: 4px;
  font-weight: normal;
  cursor: pointer;

  &:hover {
    background-color: $base-light-gray;
  }

  span {
    margin-left: 8px;
    font-size: $font-size-base;
    font-weight: normal;
    // color: $text-color;
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.profileMenuButton {
  @media screen and (max-width: breakpoint-max(sm)) {
    max-width: 48px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}