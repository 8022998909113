.loading {
  display: flex;
  min-height: calc(100vh - 200px);
  align-items: center;
  justify-content: center;
}

.report {
  background-color: #f1f1f1;
  width: 100%;
  min-height: 70px;
  border-radius: 10px;
  padding: 18px 18px;
  margin-bottom: 12px;

  display: flex;
  flex-direction: column;
}

.dateLabel {
  color: black;
}

.reportValue {
  font-weight: 600;
}

.noReports {
  display: flex;
  min-height: calc(100vh - 200px);
  align-items: center;
  justify-content: center;
}

.gap10 {
  gap: 10px;
}

.dayPicker {
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    position: absolute;
    top: 0px;
    background-color: white;
}

.datesContainer {
    background-color: #E0E0E0;
    padding: 2px 8px;
    width: fit-content;
    border-radius: 6px;
    margin-top: 15px;
    cursor: pointer;

    &:hover {
        background-color: #4A90E2;
        color: white;
    }
}

.selected {
    color: black;
    user-select: none;
    text-decoration: underline;
}

.disabled {
    color: #6e6e6e;
    user-select: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
