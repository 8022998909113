@import "../../styles/helpers";

.prjectContainer {
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 18px 18px;
}

.gap10 {
  gap: 10px;
}

:root {
  --animation-duration: 0.2s !important;
}

// .body {

//   > div {
//     animation-duration: 0.2s !important;
//   }
// }

.rootTitle {
  font-size: 14px;
  padding: 8px 0;
  color: black;
}

.addRecord {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  color: black; // #00617a;
  width: 100%;
  text-align: center;

  background-color: #f1f1f1;
  width: 100%;

  border-radius: 10px;
  padding: 18px 18px;
  margin-bottom: 12px;

  animation-duration: 0.2s !important;
  animation-fill-mode: both;
  overflow: hidden;

  user-select: none;
}

.userWithAccess {
  height: 30px;
}

.optionsButton {
  position: absolute !important;
  right: 18px;

  > button {
    position: absolute !important;
    right: 0px;
    top: 0px;
    background: transparent;
    outline: none;
    border: none;
    padding: 8px;
    border-radius: 50% !important;
    width: 34px;
    height: 34px;
    line-height: 2px !important;
    padding-bottom: 15px;
  }

  @media screen and (max-width: breakpoint-max(sm)) {
    right: 38px;
  }
}

.separator {
  background: #A3A3A3;
  height: 1px;
}

.resendLink {
  font-size: 12px;
}

.hideLast {
  > :last-child {
    display: none;
  }
}

.danger {
  color: red !important;
}