@import "../../styles/helpers";

.dimm {
  background-color: #000000c0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  height: 600px;
  width: 100%;
  background-color: white;
  max-width: 780px;
  margin-bottom: 50px;
  border-radius: 8px;
  overflow: scroll;
  position: relative;
}

.header {
  width: 100%;
  height: 50px;
  padding-left: 18px;
  padding-right: 18px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  background-color: white;
  top: 0px;
  z-index: 4;
}

.closeButton {
  cursor: pointer;
}

.body {
  height: calc(100% - 50px);

  &:last-child {
    padding-bottom: none;
  }
}

.centerLoading {
  margin: auto;
  width: fit-content;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.record {
  width: 100%;
  padding: 18px;
  border-bottom: 1px solid #e5e5e5;
}

.recordHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recordHeaderTitle {
  font-size: 14px;
  font-weight: 500;
}

.recordHeaderDate {
  font-size: 0.8rem;
  color: #8c8c8c;
}

.recordChangeTitle {
  font-size: 12px;
  font-weight: 600;
  margin-top: 12px;
}

.updateContainer {
  display: flex;
  font-size: 13px;
  flex-direction: column;
}

.tagsContainer {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.imageContainer {
  width: 75px;
  min-width: 75px;
  height: 75px;
  border-radius: 5px;
  background-color: #e7e8ea;
  position: relative;

  > * img {
    width: 100%;
    height: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
  }

  img {
    width: 100%;
    height: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
  }
}

.fileLink {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4px;

  > p {
    font-size: 8px;
    text-align: center;
    overflow: hidden;
    word-break: break-all;
  }
}
