@import "../../styles/helpers";

.footer {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 12px;
  color: black;
  font-size: 0.6rem;
}

.footerLabel {
  margin-right: 4px;
}
