@import "../../styles/helpers";

.gap10 {
  gap: 10px;
}

.searchBarRootContainer {
  margin-bottom: 30px;
  margin-top: 30px;
}

.searchBarFrame {
  // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.searchBarInput {
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  border-color: #f7f7f7 !important;
  margin-bottom: 15px;
}

.searchBarCancelButton {
  color: black !important;
  font-weight: 400 !important;
}

.searchBarCancelButton:focus {
  border: none;
  outline: none;
  box-shadow: none !important;
}

.tagsContainer {
  padding: 15px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
}

.close {
  display: inline-block;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}

.close:hover {
  opacity: 1;
}

.close:before, .close:after {
  
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.closeButtonRight {
  position: absolute;
  right: 15px;
}

.evaBigSize {
  font-size: 40px !important;
}

.searchTitle {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 25px;
  font-size: 1.2rem;
}

.tutorialText {
  font-size: 14px;
}
