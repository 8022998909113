@import "../../styles/helpers";

.tag {
    border-radius: 12px;
    background-color: #E0E0E0;
    height: 24px;
    padding: 2px 8px;

    font-size: 11px;
    font-weight: 600;
    color: #0E0E0E;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    cursor: pointer;

    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
}

.selected {
    background-color: black;// #6866E7;
    color: white;
}

.selectedIcon {
    font-size: 16px !important;
}

.tag:hover {
    opacity: 0.8;
}