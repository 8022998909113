
.pointer {
    cursor: pointer;
}

.notificationsContainer {
    overflow-y: scroll;
    max-height: calc(100vh - 76px);
}

.notificationContent {
    border-bottom: 1px solid #d1d1d1;
    text-align: left;
    padding-left: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
    position: relative;
    width: 100%;
}

.notReadBadge {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #FF5668;
    position: absolute;
    left: 0px;
    top: 19px;
}

.notificationTitle {
    font-size: 16px;
    font-weight: 600;
}

.notificationText {
    margin-top: 12px;
    font-size: 15px;

    > a {
        color: #00AEEF;
        text-decoration: none;
    }
}

.inviteButtonsContainer {
    display: flex;
    justify-content: space-between;
    align-content: stretch;
    gap: 12px;
}

.inviteButton {
    flex-grow: 1;
}

.inviteButtonAccept {
    background-color: #000000;
    color: #fff;
}

.blocked {
    opacity: 0.5;
}