.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    top: 0;
    right: 0;
    // -webkit-animation: fadein 0.1s linear forwards;
    // animation: fadein 0.1s linear forwards;
}

// @-webkit-keyframes fadein {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
// }
  
// @keyframes fadein {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
// }

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s linear;
}

.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s linear;
}