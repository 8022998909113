@import "../../styles/helpers";

.ladingHeader {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  flex-wrap: nowrap !important;

  @media (max-width: breakpoint-max(xs)) {
    overflow: visible;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}

.ladingHeaderContent {
  position: sticky;
  width: 100%;
  z-index: 1;
  height: $navbar-height;
  max-width: 850px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 30px;
  height: auto;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: black;
}

.registerLink {
    color: #296df1;
}

.registerButton {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px !important;
  gap: 8px !important;
  height: 32px !important;

  background: #f4f4f4 !important;
  border-radius: 16px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 32px !important;
  color: black !important;
  outline: none !important;
  border: none !important;
  max-width: 200px !important;
}

.loginButton {
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 32px !important;
  color: black !important;
}

.pageContainer {
  width: 100%;
  min-height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
}

.contentContainer {
  max-width: 850px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftColumn {
  width: 60%;
  display: flex;
  gap: 25px;
  flex-direction: column;

  @media (max-width: breakpoint-max(md)) {
    width: 100%;
  }
}

.title {
  font-weight: 800;
  font-size: 55px;
  line-height: 75px;
  color: black;
}

.titleIcon {
  width: 30px;
  height: auto;
  margin-left: 8px;
  margin-bottom: 10px;
}

.description {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}

.tryButton {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px !important;
  gap: 8px;

  background: #296df1 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  line-height: 24px !important;
  color: white !important;
  outline: none !important;
  border: none !important;
}

.rightColumn {
  width: 35%;
  display: flex;
  flex-direction: column;

  @media (max-width: breakpoint-max(md)) {
    display: none;
  }
}

.arrow {
  width: 10px;
  height: auto;
}
