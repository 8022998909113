@import "../../styles/helpers";

.addRecord {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: white; // #00617a;
  width: 100%;
  text-align: center;
}

.body { 
  width: 100%;

  border-radius: 4px;
  padding: 12px 18px;
  margin-bottom: 12px;

  overflow: hidden;

  > div {
    animation-duration: 0.2s !important;
  }
}

.blueBackground {
  background-color: #296df1;
}

.grayBackground {
  background-color: #f1f1f1;
}

.pointer {
  cursor: pointer;
}

.recordTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #000000;
}

.amountTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #000000;
}

.gap10 {
  gap: 10px;
}

.mw70 {
  max-width: 70%;
}

.allFilesContainer {
  overflow: scroll;
}

.emptyFile {
  position: relative;

  border: 2px dashed #56678942;
  height: 75px;
  width: 75px;
  min-width: 75px;
  border-radius: 10px;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}

.filesUploadContainer {
  position: relative;

  border: 2px dashed #56678942;
  height: 75px;
  width: 75px;
  min-width: 75px;
  border-radius: 10px;
  cursor: pointer;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}

.filesUploadContainer:hover {
  background-color: #56678910;
}

.addFileButton {
  padding: 2px 8px;
  background-color: #a3a3a3;
  color: white;
  border-radius: 5px;
}

.fileUpload {
  opacity: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.dropZoneOverlay {
  font-size: 12px;
  text-align: center;
  gap: 4px;
  margin-top: 4px;
}

.addFileDescription {
  margin-top: 4px;
}

.additionalText {
  font-size: 12px;
}

.uploadErroContainer {
  background-color: #fdf4f4;
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid #d9b5b3;
  position: relative;
}

.uploadErrorTitle {
  color: black;
  font-weight: 800;
  font-size: 13px;
}

.uploadErrorDescription {
  color: black;
  font-weight: normal;
  font-size: 13px;
}

.closeIcon {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 18px;
  cursor: pointer;
}

.file {
  width: 75px;
  min-width: 75px;
  height: 75px;
  border-radius: 5px;
  background-color: #e7e8ea;
  position: relative;

  > * img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}

.filesHelp {
  width: 14px;
  height: 14px;
  margin-left: 2px;
  margin-bottom: 2px;
}

.fileLink {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  > p {
    font-size: 8px;
    text-align: center;
    overflow: hidden;
    word-break: break-all;
  }
}

.removeAttachmentButton {
  height: 21px;
  width: 21px;
  border-radius: 50% !important;
  padding: 0px !important;
  background-color: white !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2px;
  top: 2px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    opacity: 1;

    > i {
      color: #aa1d34 !important;
    }
  }
}

.removeAttachmentButtonIcon {
  color: #a8a8a8 !important;
  font-size: 13px !important;
}

.tagInput {
  // width: auto !important;
  // height: 24px !important;
  // box-sizing: border-box !important;
  // border-radius: 12px !important;
  // background-color: #E0E0E0 !important;
  // padding: 2px 8px !important;
  // font-size: 13px !important;
  // font-weight: 600 !important;
  // color: #0E0E0E !important;
  // cursor: pointer !important;
  // border: none !important;
}

.tagInput:focus-visible {
  border: none !important;
  outline: none;
}

.inputSizer {
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
  border: none;
  padding: 0px 28px 0px 12px;
  margin: 0px;
  height: 24px;
  border-radius: 12px;
  background-color: #e0e0e0;

  &.stacked {
    padding: 0px;
    align-items: stretch;

    &::after,
    input,
    textarea {
      grid-area: 2 / 1;
    }
  }

  &::after,
  input,
  textarea {
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    font: inherit;
    padding: 0px;
    margin: 0px;
    resize: none;
    background: none;
    appearance: none;
    border: none;
    height: 100%;
    font-size: 13px;
    font-weight: 600;
  }

  span {
    padding: 0.25em;
  }

  &::after {
    content: attr(data-value) " ";
    visibility: hidden;
    white-space: pre-wrap;
  }

  &:focus-within {
    outline: none;

    > span {
      color: blue;
    }

    textarea:focus,
    input:focus {
      outline: none;
    }
  }
}

.addTagButton {
  font-size: 16px !important;
  position: absolute;
  right: 8px;
}

/* pretty radio */
label > input[type="radio"] {
  display: none;
}

input[type="radio"] + span {
  font-size: 14px;

  display: flex;
  align-items: center;
}

label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}

label > input[type="radio"]:checked + * {
  color: black;
}

label > input[type="radio"]:checked + *::before {
  background: radial-gradient(
    black 0%,
    black 40%,
    transparent 50%,
    transparent
  );
  border-color: black;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
