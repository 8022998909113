@import "../../styles/helpers";

.sideBarNew {
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 

    width: 100%;
    max-width: 400px;

    @media screen and (max-width: breakpoint-max(sm)) {
        width: 100%;
        max-width: 100%;
    }
}

.sideDrawer { 
    transform: translateX(120%);
    transition: transform 0.3s ease-out;
}

.sideDrawerOpen {
    transition: transform 0.3s ease-out;
    transform: translateX(0);
 }

 .closeButtonRight {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  
  .evaBigSize {
    font-size: 40px !important;
  }

  .drawerContent {
      margin: 10px;
      // width: 100%;
  }

  .drawerTitle {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 25px;
    font-size: 1.2rem;
  }