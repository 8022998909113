@import "../../styles/helpers";

.addRecord {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 8px;

  background: #f1f1f1;
  border-radius: 10px;

  cursor: pointer;

  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  color: #296df1;

  margin-bottom: 18px;
}

.recordBody {
  background-color: #f1f1f1;
  width: 100%;
  min-height: 70px;
  border-radius: 10px;
  padding: 18px 18px;
  margin-bottom: 12px;
  cursor: pointer;
}

.recordTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;

  color: #000000;
}

.amountTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;

  color: #000000;
}

.gap10 {
  gap: 10px;
}

.widgetBlock {
  padding: 12px 0;
  background-color: $base-light-gray;
  border-radius: 8px;
}

.widgetBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.verticalWidgetBody {
  padding: 0 16px;
}

.widgetButton {
  width: 40px;
  height: 20px;
  box-sizing: content-box;
  padding: 0px;
  margin-right: 10px;
}

.smallWidget {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  .mutedPink {
    background-color: #ff566830;
  }

  .mutedYellow {
    background-color: #ffc40530;
  }

  .mutedTeal {
    background-color: #41d5e230;
  }

  .mutedViolet {
    background-color: #4d53e030;
  }
  //  доделать для остальных цветов прогресс баров
}

.image {
  margin-right: 16px;
  width: 80px;
  height: 80px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.userParams {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 32px;
}

.goals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 54px 0;
}

.goalsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.goal {
  display: flex;
  flex-direction: column;
}

.activity {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.statsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  padding: 24px 36px;
  border-radius: 8px;

  @media (max-width: breakpoint-max(sm)) {
    padding: 20px 24px;
  }

  .pieImg {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
}

.responsiveWidget {
  @media (max-width: breakpoint-max(lg)) {
    padding: 24px 12px;
  }
}

.readOnlyBanner {
  background: #fceeb5;
  width: 100%;
  // min-height: 70px;
  border-radius: 4px;
  padding: 18px 18px;
  margin-bottom: 12px;

  text-align: center;
  color: #676767;
}

.tutorial {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.tutorialArrow {
  height: 60px;
  width: auto;
}

.tutorialTitle {
  font-size: 14px;
  color: black;
  margin-top: 12px;
}

.tutorialDescription {
  font-size: 14px;
  color: #a3a3a3;
  margin-top: 20px;
  max-width: 50%;

  @media screen and (max-width: breakpoint-max(xs)) {
    max-width: 80%;
  }
}

.centerLoading {
  margin: auto;
  width: fit-content;
}

.dateLabel {
  color: black;
}

.loading {
  display: flex;
  min-height: calc(100vh - 200px);
  align-items: center;
  justify-content: center;
}
