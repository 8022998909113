@import "../../styles/helpers";

.prjectContainer {
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 18px 18px;
}

.gap10 {
  gap: 10px;
}

:root {
  --animation-duration: 0.2s !important;
}

.addRecord {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  color: black; // #00617a;
  width: 100%;
  text-align: center;

  background-color: #f1f1f1;
  width: 100%;

  border-radius: 10px;
  padding: 18px 18px;
  margin-bottom: 12px;

  animation-duration: 0.2s !important;
  animation-fill-mode: both;
  overflow: hidden;

  user-select: none;
}

.userWithAccess {
  height: 30px;
}

.rootTitle {
  font-size: 14px;
  padding: 8px 0;
  color: black;
}

.subscription_title {
  font-size: 14px;
  color: black;
  font-weight: 400 !important;
  margin-top: 3px;
}

.subscription_description {
  font-size: 14px;
  color: #A3A3A3;
}
