.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.modalWrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: fit-content;
  transform: translate(-50%, 0);
  left: 50%;
  max-width: 780px;
}

.modal {
  z-index: 100;
  background: white;
  border-radius: 3px;
  // max-width: 500px;
  padding: 2rem;
  text-align: left;
  
  
  /* top: 50%; */
  // transform: translate(-50%, 0);
  // width: 100%;
  margin: 10px;
  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
}

.modalCloseButton {
  height: 39px;
}

.gap10 {
  gap: 10px;
}